import React from "react";

const RecipeModal = props => (
  <div className="modal-container">
    <div className="modal-content">
        <div className="recipe-details">
      <div className="ingredients">
        <h3>Ingredients:</h3>
        {props.recipe.ingredients
          ? props.recipe.ingredients.map(i => {
              return <div>{i}</div>;
            })
          : "No ingredient data available"}
      </div>
      <div className="steps">
        <h3>Steps:</h3>
        {props.recipe.steps
          ? props.recipe.steps.map((i, index) => {
              return <div className={"recipe-step"}><b>{index + 1 +". "}</b>{i}</div>;
            })
          : "No step data available"}
      </div>
      </div>
      <button className={"close-button"} onClick={props.closeModal}>
        Close
      </button>
    </div>
  </div>
);
export default RecipeModal;
