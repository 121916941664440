import React from "react";
import "../App.scss";
import { food, categories } from "./food";
import Switch from "react-switch";
import logo from "../assets/slurpalt.png";
import RecipeModal from "./recipeModal";

class FoodPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foodItem: { name: "Press the button" },
      pastItems: [],
      filters: [],
      foodList: food,
      healthyFilter: false,
      cheapFilter: false,
      specialFilter: false,
      vegetarianFilter: false,
      seafoodFilter: false,
      meatFilter: false,
      buttonText: "GO!",
      visible: true,
      showModal: false
    };

    this.getFood = this.getFood.bind(this);
    this.getRandomItem = this.getRandomItem.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  async toggleFilter(id) {
    let filterArray = this.state.filters;

    if (!filterArray.includes(id)) {
      filterArray.push(id);
    } else {
      filterArray.splice(filterArray.indexOf(id), 1);
    }

    let { healthyFilter,
      cheapFilter,
      specialFilter,
      vegetarianFilter,
      seafoodFilter,
      meatFilter } = this.state;

    switch (id) {
      case 1:
        healthyFilter = !this.state.healthyFilter;
        break;
      case 4:
        cheapFilter = !this.state.cheapFilter;
        break;
      case 2:
        specialFilter = !this.state.specialFilter;
        break;
      case 3:
        vegetarianFilter = !this.state.vegetarianFilter;
        break;
      case 5:
        seafoodFilter = !this.state.seafoodFilter;
        break;
      case 6:
        meatFilter = !this.state.meatFilter;
        break;
      default:
    }

    await this.setState({
      foodList: [],
      filters: filterArray,
      healthyFilter: healthyFilter,
      cheapFilter: cheapFilter,
      specialFilter: specialFilter,
      vegetarianFilter: vegetarianFilter,
      seafoodFilter: seafoodFilter,
      meatFilter: meatFilter
    });
  }

  getRandomItem() {
    let foodList = food;
    let item;
    const filters = this.state.filters;

    if (filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        foodList = foodList.filter(item =>
          item.categories.includes(filters[i])
        );
      }
    }

    if (foodList.length > 0) {
      const index = Math.floor(Math.random() * foodList.length);
      item = foodList[index];
      this.setState({
        foodList: foodList
      });
    } else {
      item = { name: "Sorry, no dishes match your criteria" };
    }

    return item;
  }

  getFood() {
    const item = this.getRandomItem();
    const itemArray = this.state.pastItems;
    const self = this;
    this.setState({
      visible: false
    });
    for (let i = 0; i < itemArray.length; i++) {
      if (itemArray[i] === item) {
        return self.getFood();
      }
    }

    setTimeout(function() {
      itemArray.unshift(item);
      if (itemArray.length === self.state.foodList.length) {
        itemArray.pop();
      }
      self.setState({
        foodItem: item,
        pastItems: itemArray,
        visible: true
      });
    }, 150);
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideModal() {
    this.setState({
      showModal: false
    });
  }

  render() {
    const item = this.state.foodItem;
    const slurpRed = "#eb0d32";
    const lightGrey = "#c7c7c7";
    console.log(this.state.showModal);
    return (
      <div>
        <div className={"header"}>
          <img src={logo}></img>
        </div>
        <div
          className={
            this.state.visible
              ? "suggestion-box fade-in"
              : "suggestion-box fade-out"
          }
          onClick={this.showModal}
        >
          {item.name}
        </div>
        <div className={"button-container"}>
          <button className={"suggestion-button"} onClick={this.getFood}>
            {this.state.buttonText}
          </button>
        </div>
        <div className={"filter-box"}>
          <div className="filter-toggle">
            <label>Healthy</label>
            <div className={"toggle-switch"}>
              <Switch
                height={30}
                width={60}
                handleDiameter={24}
                onColor={slurpRed}
                offColor={lightGrey}
                activeBoxShadow={"#fa0707"}
                onChange={() => this.toggleFilter(1)}
                checked={this.state.healthyFilter}
              ></Switch>
            </div>
          </div>
          <div className="filter-toggle">
            <label>Cheap</label>
            <div className={"toggle-switch"}>
              <Switch
                height={30}
                width={60}
                handleDiameter={24}
                onColor={slurpRed}
                offColor={lightGrey}
                activeBoxShadow={"#fa0707"}
                onChange={() => this.toggleFilter(4)}
                checked={this.state.cheapFilter}
              ></Switch>
            </div>
          </div>
          <div className="filter-toggle">
            <label>Special</label>
            <div className={"toggle-switch"}>
              <Switch
                height={30}
                width={60}
                handleDiameter={24}
                onColor={slurpRed}
                offColor={lightGrey}
                activeBoxShadow={"#fa0707"}
                onChange={() => this.toggleFilter(2)}
                checked={this.state.specialFilter}
              ></Switch>
            </div>
          </div>
          <div className="filter-toggle">
            <label>Vegetarian</label>
            <div className={"toggle-switch"}>
              <Switch
                height={30}
                width={60}
                handleDiameter={24}
                onColor={slurpRed}
                offColor={lightGrey}
                activeBoxShadow={"#fa0707"}
                onChange={() => this.toggleFilter(3)}
                checked={this.state.vegetarianFilter}
              ></Switch>
            </div>
          </div>
          <div className="filter-toggle">
            <label>Seafood</label>
            <div className={"toggle-switch"}>
              <Switch
                height={30}
                width={60}
                handleDiameter={24}
                onColor={slurpRed}
                offColor={lightGrey}
                activeBoxShadow={"#fa0707"}
                onChange={() => this.toggleFilter(5)}
                checked={this.state.seafoodFilter}
              ></Switch>
            </div>
          </div>
          <div className="filter-toggle">
            <label>Meat</label>
            <div className={"toggle-switch"}>
              <Switch
                height={30}
                width={60}
                handleDiameter={24}
                onColor={slurpRed}
                offColor={lightGrey}
                activeBoxShadow={"#fa0707"}
                onChange={() => this.toggleFilter(6)}
                checked={this.state.meatFilter}
              ></Switch>
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <RecipeModal
            recipe={this.state.foodItem}
            closeModal={this.hideModal}
          ></RecipeModal>
        )}
      </div>
    );
  }
}

export default FoodPicker;
