import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import FoodPicker from '../src/foodPicker/foodPicker';
import './App.scss';

import SignInPage from './login/login';
import Navigation from './navigation/nav';
import { withFirebase } from './firebase/context';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      colours: ['#6bc790', '#632f53', '#9e5454', '#828ab3', '#f27b1f', '#3aba7a', '#1e5d69'],
      colourScheme: null
    };
  }

  componentDidMount() {
    const colours = this.state.colours;
    const index = Math.floor(Math.random() * colours.length);

    this.setState({
      colourScheme: 'white'
    })
    
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null });
      },
    );
  }

  componentWillUnmount() {
    this.listener();
  }

render() {
  return (
    <Router>
    <div>{this.state.authUser ? <FoodPicker colourScheme={this.state.colourScheme}/> : <SignInPage colourScheme={this.state.colourScheme}/>}</div>
    </Router>
  );
}
}
export default withFirebase(App);
