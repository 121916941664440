import app from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAEaLU4bAPgx-0m52hGwpVgMjYNrc-Syqw",
    authDomain: "foodpicker-d462a.firebaseapp.com",
    databaseURL: "https://foodpicker-d462a.firebaseio.com",
    projectId: "foodpicker-d462a",
    storageBucket: "foodpicker-d462a.appspot.com",
    messagingSenderId: "764757805499",
    appId: "1:764757805499:web:c9830fc11e4b43625b5c9e",
    measurementId: "G-Z0S5Q8WR6Z"
  };
class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
  }

    doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}
export default Firebase;