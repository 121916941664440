export const categories = {
  healthy: 1,
  special: 2,
  vegetarian: 3,
  cheap: 4,
  seafood: 5,
  meat: 6
};

export const food = [
  {
    name: "Shredded duck with hoisin sauce in lettuce wraps",
    categories: [1, 6],
    ingredients: []
  },
  {
    name: "Prawn wraps",
    categories: [1, 5],
    ingredients: []
  },
  {
    name: "Ramen",
    categories: [2, 6],
    ingredients: []
  },
  {
    name: "Tarka Daahl",
    categories: [1, 3, 4],
    ingredients: []
  },
  {
    name: "Vindaloo",
    categories: [2, 6],
    ingredients: []
  },
  {
    name: "Chop-suey",
    categories: [1, 6],
    ingredients: []
  },
  {
    name: "Vegetable stir-fry",
    categories: [1, 3, 4],
    ingredients: []
  },
  {
    name: "Cod curry",
    categories: [1, 5],
    ingredients: []
  },
  {
    name: "Kimchi rice",
    categories: [1, 3],
    ingredients: [
      "150g kimchi",
      "2/3 cup of rice",
      "1/2 cup of peas",
      "1/2 cup sweetcorn",
      "2 eggs",
      "1 tbsp gochujang",
      "1 tbsp sesame oil",
      "3 tbsp soy sauce",
      "2 garlic gloves, crushed",
      "Sesame seeds",
      "2 spring onions, chopped"
    ],
    steps: [
      "Cook the rice.",
      "In the meantime while the rice cooks, prepare the kimchi. Squeeze as much juice from the kimchi as you can into a bowel. Into the bowl with the kimchi juice, add the gochujang and soy sauce, stiring well to mix it together. Cut the kimchi into small peices. Using a splash of oil, fry the eggs in a pan and set aside once cooked.",
      "When the rice is ready, heat the sesame oil in a large pain over a high heat. Add the crushed garlic and cooked for about 30 seconds before adding the rice and kimchi juice. Continiously move the rice around to stop it sticking to the pan. After a couple of minutes, add the chopped kimchi, peas and sweetcorn. Still moving everything around, keep cooking everything for 2-3 minutes.",
      "Divide the rice into bowls. Add more soy sauce to taste, if necessary. Add a friend egg on top of each, then sprinkle with sesame seeds and chopped spring onion."
    ]
  },
  {
    name: "Pho",
    categories: [1, 2, 6],
    ingredients: []
  },
  {
    name: "Spinach strudel",
    categories: [2, 3],
    ingredients: []
  },
  {
    name: "Spaetzeln with chicken and mushroom",
    categories: [6],
    ingredients: []
  },
  {
    name: "Asparagus and broccoli spaghetti",
    categories: [1, 3, 4],
    ingredients: []
  },
  {
    name: "Tomato and basil pasta",
    categories: [1, 3, 4],
    ingredients: []
  },
  {
    name: "Leek and artichoke tortellini",
    categories: [3, 4],
    ingredients: []
  },
  {
    name: "Gnocchi with cauliflower and cheese",
    categories: [1, 3, 4],
    ingredients: []
  },
  {
    name: "Fajitas",
    categories: [6],
    ingredients: [
      "1 tbsp sweet smoked paprika",
      "1 tsp ground cumin",
      "1 tsp garlic powder",
      "1 tsp ground coriander",
      "1 heaped tsp dried mexican oregano  (regular oregano is also fine)",
      "Salt and pepper to taste",
      "1 green pepper",
      "1 red pepper",
      "2 chicken breasts",
      "Tortilla wraps",
      "1 tsp hot chilli powder",
      "1 tbsp vegetable oil",
      "Onion",
      "Lettuce",
      "Salsa"
    ],
    steps: [
      "Mix toghether the chilli powder, cumin, garlic powder, coriander, oregano, salt and pepper to make the fajita seasoning.",
      "Chop the peppers and onions. Cut the chicken breast into thin strips",
      "Cook the chicken in a pan with 1 tbsp vegetable oil until it begins to turn golden. Remove the chicken and add the peppers and onions with more oil if necessary.",
      "When the peppers and onions begin to soften, re-add the chicken. Add the fajita seasoning and stir well.",
      "Serve on tortillas with lettuce and salsa."
    ]
  },
  {
    name: "Vegetable fajitas",
    categories: [1, 3],
    ingredients: [
      "1 tbsp sweet smoked paprika",
      "1 tsp ground cumin",
      "1 tsp garlic powder",
      "1 tsp ground coriander",
      "1 heaped tsp dried mexican oregano (regular oregano is also fine)",
      "Salt and pepper to taste",
      "1 green pepper",
      "1 red pepper",
      "Handful of mushrooms",
      "Tortilla wraps",
      "1 tsp hot chilli powder",
      "1 tbsp vegetable oil",
      "Onion",
      "Lettuce",
      "Salsa"
    ],
    steps: [
      "Mix toghether the chilli powder, cumin, garlic powder, coriander, oregano, salt and pepper to make the fajita seasoning.",
      "Chop the peppers, mushrooms and onions. Add the mushrooms to a pan with the oil over a medium heat.",
      "When the mushrooms begin to change colour, add the peppers and onions.",
      "When the peppers and onions begin to soften, add the fajita seasoning and stir well.",
      "Serve on tortillas with lettuce and salsa."
    ]
  },
  {
    name: "Burrito rice",
    categories: [1, 3]
  },
  {
    name: "Chicken tagine",
    categories: [1, 6]
  },
  {
    name: "Cheesy tuna pasta bake",
    categories: [2, 4, 5]
  },
  {
    name: "Gulash",
    categories: [2, 6]
  },
  {
    name: "Salmon creme fraiche pasta",
    categories: [5]
  },
  {
    name: "Vegetable fritters",
    categories: [1, 3, 4]
  },
  {
    name: "Noodle omelette",
    categories: [3, 4]
  },
  {
    name: "Egg and beans on toast",
    categories: [1, 3, 4],
    ingredients: [
      "1/2 tin Heinz baked beans",
      "2 slices toast, buttered",
      "2 eggs",
      "1/2 tbsp sunflower sunflower oil"
    ],
    steps: [
      "Cook the beans as per the instructions on the tin. Fry the eggs in a pan with the sunflower oil.",
      "Place an egg on each slice of toast. Pour the cooked beans over the top."
    ]
  },
  {
    name: "Vegetable burger wraps",
    categories: [3, 4]
  },
  {
    name: "Spinach curry",
    categories: [1, 4]
  },
  {
    name: "Garlic and prawn pasta",
    categories: [1, 5]
  },
  {
    name: "Spinach curry",
    categories: [1, 4]
  },
  {
    name: "Chicken burgers with potato wedges",
    categories: [6]
  },
  {
    name: "Fish and rice with chimichurri sauce",
    categories: [2, 5]
  },
  {
    name: "Vegetable curry",
    categories: [1, 3, 4]
  },
  {
    name: "Healthy jalfrezi",
    categories: [1, 6]
  },
  {
    name: "Egg fried rice",
    categories: [1, 3, 4]
  },
  {
    name: "Steak",
    categories: [2, 6]
  },
  {
    name: "Chilli",
    categories: [2, 6]
  },
  {
    name: "Duck stir-fry",
    categories: [1, 2, 6]
  },
  {
    name: "Tikka masala",
    categories: [2, 6]
  },
  {
    name: "Homemade burgers",
    categories: [2, 6]
  },
  {
    name: "Homemade pizza",
    categories: [2]
  },
  {
    name: "Hotdogs",
    categories: [2, 4, 6]
  },
  {
    name: "Cullen skink with bread",
    categories: [2, 5]
  },
  {
    name: "Sweat and sour chicken",
    categories: [2, 6]
  },
  {
    name: "Vegetable lasange",
    categories: [3]
  },
  {
    name: "Gyoza",
    categories: [1, 2, 4, 6]
  },
  {
    name: "Chicken and apricot stew",
    categories: [1, 6]
  },
  {
    name: "Pasta salad with mozzarella and olives",
    categories: [1, 3, 4]
  },
  {
    name: "Savoury pancakes",
    categories: [1, 4]
  },
  {
    name: "Carbonara",
    categories: [4, 6]
  },
  {
    name: "Falafels with flatbread",
    categories: [1, 3, 4]
  },
  {
    name: "Prawn and chorizo jambalaya",
    categories: [2, 5, 6]
  },
  {
    name: "Coconut Fish Curry",
    categories: [5]
  },
  {
    name: "Soy mirin salmon fillet with noodles and beansprouts",
    categories: [1, 5]
  },
  {
    name: "Salmon and scrambled egg on toast",
    categories: [1, 5],
    ingredients: [
      "2 eggs",
      "1 tbsp creme fraiche",
      "1/2 pack of smoked salmon",
      "2 slices of toast",
      "1 tsp olive oil"
    ],
    steps: [
      "Whisk the eggs and creme fresh in a bowl, then cut the salmon into small pieces.",
      "Heat the olive oil in a pan over medium heat, then pour in the egg mixture and salmon.",
      "Keep stiring the mixture in the pan to make sure it doesn't stick. Do this until the mixture is solid, but not too dry.",
      "Butter the toast before adding the scrambled eggs and salmon. Salt and pepper to taste."
    ]
  },
  {
    name: "Tom kha gai",
    categories: [1, 2, 5],
    ingredients: [],
    steps: []
  },
  {
    name: "Quesadillas with homemade guacamole",
    categories: [2, 6],
    ingredients: [],
    steps: []
  },
  {
    name: "Arrabbiata Pasta",
    categories: [1, 3, 4],
    ingredients: [],
    steps: []
  },
  {
    name: "Mushroom and spinach risotto",
    categories: [1, 3, 4],
    ingredients: [],
    steps: []
  }
];
